<template>
<el-container class="container backBoxbb" ref="container1" 
    style="display: flex;flex-direction:column;" >

<el-main ref="elmaincards1" style="justify-content: flex-start;display: flex;flex-direction:column;">
  <div class="flex_row" style="max-width:700px;width:100%;flex-wrap:wrap;">
    <div class="flex_row" style="width:100%;max-width:350px;">
    <el-date-picker
      v-model="valueDate[0]" style="max-width:250px;margin:0px 0px 10px 0;"
      type="date" value-format="yyyy-MM-dd"
      placeholder="开始日期" :size="size">
    </el-date-picker><span style="line-height: 32px;margin:0px 5px 10px">—</span>
    <el-date-picker
      v-model="valueDate[1]" style="max-width:250px;margin:0px 10px 10px 0;"
      type="date" value-format="yyyy-MM-dd"
      placeholder="结束日期" :size="size">
    </el-date-picker>
    </div>
    <div class="flex_row" style="width:100%;max-width:350px;">
      <el-select v-model="station" clearable :size="size" 
          placeholder="站点" style="margin:0 10px 10px 0;flex-grow:1">
        <el-option
          v-for="item in stationList"
          :key="item.station"
          :label="item.station"
          :value="item.station">
        </el-option>
      </el-select>
      <el-input :size="size" v-model="usecode" 
        :maxlength="11" clearable style="margin:0px 10px 10px 0;">
        <span slot="prepend">关键字</span>
      </el-input>
    </div>
  </div>
  <div class="flex_row" style="max-width:700px;width:100%;flex-wrap:wrap;">
    <el-button type="success"  :size="size"  class="button" :plain="activebt!=1" style="margin:0 10px 10px 0;"
      @click="getSaleList(1)">{{btCaption[0]}}</el-button>
    <el-button type="success"  :size="size"  class="button" :plain="activebt!=2" style="margin:0 10px 10px 0;"
      @click="getSaleList(2)">{{btCaption[1]}}</el-button>
    <el-button type="success"  :size="size"  class="button" :plain="activebt!=3" style="margin:0 10px 10px 0;"
      @click="getSaleList(3)">{{btCaption[2]}}</el-button>
    <el-button type="success"  :size="size"  class="button" :plain="activebt!=4" style="margin:0 10px 10px 0;"
      @click="getSaleList(4)">{{btCaption[3]}}</el-button>
    <el-button type="success"  :size="size"  class="button" :plain="activebt!=5" style="margin:0 10px 10px 0;"
      @click="getSaleList(5)">{{btCaption[4]}}</el-button>
    <el-button type="success"  :size="size"  class="button" :plain="activebt!=6" style="margin:0 10px 10px 0;"
      @click="getSaleList(6)">{{btCaption[5]}}</el-button><div style="flex-grow:1;"></div>
    <el-button type="primary"  :size="size"  class="button" style="margin:0 10px 10px 0;" :loading="downfileloading"
      @click="exportToExcel">导出</el-button>
  </div>
  <el-table v-if="activebt<=2"  height="700" :size="size" ref="multipleTable" :data="QueryData1" highlight-current-row 
    v-loading="listLoading" element-loading-text="Loading..." :fit="true" :key="refrsh"
				element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.65)"
				border tooltip-effect="dark" :header-cell-style="tableHeaderColor"  >
      <div slot="empty" >暂无数据</div>
      <el-table-column prop="px" label="序" width="40" align1="center" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="station" label="站点" width="90"  min-width="70"  ></el-table-column>
      <el-table-column v-if="activebt!=1" prop="cdate" label="检查日期" width="90" min-width="60" show-overflow-tooltip ></el-table-column>
      <el-table-column prop="aj1" label="巡检户次" width="90" min-width="60"  ></el-table-column>
      <el-table-column prop="aj2" label="安检户次" width="90" min-width="60"  ></el-table-column>
      <el-table-column prop="jm1" label="居民户次" width="90" min-width="60"  ></el-table-column>
      <el-table-column prop="jm2" label="非居民户次" width="110" min-width="60"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="jg1" label="合格户次" width="90" min-width="60"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="jg2" label="一般隐患" width="90" min-width="60"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="bj1" label="消除隐患" width="90" min-width="60"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="jg3" label="严重隐患" width="90" min-width="60"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="bj2" label="整改合格" width="90" min-width="60"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="pz1" label="拍照"  min-width="60"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="pz2" label="未拍照"  min-width="60"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="js1" label="上报"  min-width="60"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="js2" label="未上报"  min-width="60"  show-overflow-tooltip></el-table-column>
    </el-table>
  <el-table v-if="activebt==3"  height="700" :size="size" ref="multipleTable" :data="QueryData2" highlight-current-row 
    v-loading="listLoading" element-loading-text="Loading..."  :fit="true" :key="refrsh"
				element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.65)"
				border tooltip-effect="dark" :header-cell-style="tableHeaderColor"  >
      <div slot="empty" >暂无数据</div>
      <el-table-column prop="px" label="序" width="40" align1="center" >
      </el-table-column>
      <el-table-column prop="station" label="站点" width="60"  min-width="70"  ></el-table-column>
      <el-table-column prop="czname" label="安检员" width="70" min-width="60" show-overflow-tooltip ></el-table-column>
      <el-table-column prop="pz1" label="计费次数" width="80" min-width="60" show-overflow-tooltip></el-table-column>
      <el-table-column prop="sc1" label="首次安检" width="80" min-width="60" show-overflow-tooltip></el-table-column>
      <el-table-column prop="sc2" label="距上次<180天" width="120" min-width="60" show-overflow-tooltip></el-table-column>
      <el-table-column prop="sc3" label="<360" width="70" min-width="60" show-overflow-tooltip></el-table-column>
      <el-table-column prop="sc4" label=">360" width="70" min-width="60" show-overflow-tooltip></el-table-column>
      <el-table-column prop="aj1" label="巡检户次" width="80" min-width="60"  ></el-table-column>
      <el-table-column prop="aj2" label="安检户次" width="80" min-width="60"  ></el-table-column>
      <el-table-column prop="jm1" label="居民户次" width="80" min-width="60"  ></el-table-column>
      <el-table-column prop="jm2" label="非居民户次" width="100" min-width="60"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="jg1" label="合格户次" width="80" min-width="60"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="jg2" label="一般隐患" width="80" min-width="60"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="js1" label="上报"  min-width="60"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="js2" label="未上报"  min-width="70"  show-overflow-tooltip></el-table-column>
    </el-table>
    <el-table v-if="activebt>3"  height="700" :size="size" ref="multipleTable3" :data="QueryData3" highlight-current-row 
    v-loading="listLoading" element-loading-text="Loading..."  :fit="true" :key="refrsh" @current-change="handleCurrentChange"
				element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.65)"
				border tooltip-effect="dark" :header-cell-style="tableHeaderColor"  >
      <div slot="empty" >暂无数据</div>
      <el-table-column prop="px" label="序" width="40" align1="center" >
      </el-table-column>
      <!-- px,station,czname,cztime,checktype,checkresult,Contact,mobile,m_syzbh,custtype,m_caddress,upflag,images,cmemo -->
      <el-table-column prop="station" label="站点" width="60"  min-width="70"  ></el-table-column>
      <el-table-column prop="czname" label="安检员" width="70" min-width="60" show-overflow-tooltip ></el-table-column>
      <el-table-column prop="cztime" label="安检时间" width="120" min-width="60" show-overflow-tooltip ></el-table-column>
      <el-table-column prop="checktype" label="类型" width="60" min-width="60" show-overflow-tooltip></el-table-column>
      <el-table-column prop="checkresult" label="安检结果" width="80" min-width="60" show-overflow-tooltip></el-table-column>
      <el-table-column label="照片" align="center" width="50">
        <i class="el-icon-picture-outline" v-if="scope.row.images!=''" slot-scope="scope" 
         style="font-size:18px;cursor:pointer;"
         @click="showimage(scope.$index)"></i>
      <!-- <el-image slot-scope="scope" v-if="scope.row.images!=''"
    style="width: 18px; height:18px"
    :src="url" 
    :preview-src-list="scope.row.imagelist">
  </el-image> -->
      </el-table-column>
      <el-table-column prop="Contact" label="客户名称" width="90" min-width="60" show-overflow-tooltip></el-table-column>
      <el-table-column prop="mobile" label="联系电话" width="100" min-width="60" show-overflow-tooltip></el-table-column>
      <el-table-column prop="m_syzbh" label="使用证" width="80" min-width="60" show-overflow-tooltip></el-table-column>
      <el-table-column prop="custtype" label="类别" width="60" min-width="60" show-overflow-tooltip ></el-table-column>
      <el-table-column prop="m_caddress" label="安检地址" width="100" min-width="60" show-overflow-tooltip></el-table-column>
      <el-table-column prop="upflag" label="上报" width="60" min-width="60"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="images" label="图片id" width="70" min-width="60"  show-overflow-tooltip></el-table-column>
      <el-table-column  label="结果详情" min-width="60" >
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" placement="left">
              <!-- // {{}}会将数据解释为普通文本，而非 HTML 代码。 -->
              <div v-html="scope.row.cmemo" slot="content"></div>
              <div class="oneLine">{{scope.row.cmemo}}</div>
            </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
</el-main>
<el-pagination style="margin-bottom:5px;" 
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :current-page.sync="pageno"
        :page-sizes="[20, 50, 100]"
        :page-size="pagesize"
        :layout="pagelayout"
        :total="totalcount">
      </el-pagination>
  <el-drawer
        title1="安检照片" direction="btt" :withHeader="false" class="backBoxbc"
        :append-to-body="true" :size="'100%'"
        :visible.sync="showajImage">
        <div style="width:100%;height:100%;position:relative;">
        <div style="justify-content:flex-start;display:flex;flex-direction:column;width:100%;
        height:100%;overflow-y:auto;position:absolute;">
        <div v-for="item in ajImageLists" :key="item" @click="showajImage=false">
            <div style="width:80%;margin:20px auto;" @click.stop="showajImage=true"> 
              <el-image  style1="width:100%;"
            :src="item" 
            fit="scale-down"></el-image>
            </div>
        </div>
        </div>
        <div style="position:absolute;left:40%;bottom:5%;justify-content:space-between;display:flex;width:200px;">
          <el-button icon="el-icon-arrow-left" circle style="background-color:rgba(255,255,255,0.5);font-size:26px;
          font-weight: 700;" @click.stop="nextPre('p')"></el-button>
          <el-button icon="el-icon-arrow-right" circle style="background-color:rgba(255,255,255,0.5);font-size:26px;
          font-weight:700;" @click.stop="nextPre('n')"></el-button>
          <el-button icon="el-icon-close" circle style="background-color:rgba(255,255,255,0.5);font-size:26px;
          font-weight: 700; " @click.stop="showajImage=false"></el-button>
          </div>
        </div>
      </el-drawer>
</el-container>  
</template>

<script>
/* eslint-disable */
export default {
  data() {
    return {
      size: 'medium',//medium,small,mini,
      tableHeight:500,
      listLoading:false,
      downfileloading:false,
      valueDate:['2022-02-01','2022-03-06'],
      station:"",
      stationList:[],
      QueryData1:[],
      QueryData2:[],
      QueryData3:[],
      usecode:"",
      activebt:0,
      pageno:1,
      pagesize:20,
      totalcount:0,//记录总数
      pagelayout:"total,sizes,prev,pager,next,jumper",
      btCaption:['站点','报表','安检计费','合格明细','一般隐患','严重隐患'],
      refrsh:true,
      ajImageLists:[],
      showajImage:false,
      QueryData3index:0,
    }
  },
  watch:{

  },
  computed: {
    
  },
  mounted(){
    this.valueDate=[this.$api.formatDate(new Date()),this.$api.formatDate(new Date())]
    this.getStationList();
    this.initDivSize();
  },
  methods: {
    exportToExcel(){
      this.downfileloading=true;
      let data = {
        lx: "getsecuritylistexcel",
        sqlone:this.activebt,
        procext:"report"
      };
      this.$api.post("",data,(res) => {
          if (res["status"] == "1") {
            var headers=res["headers"].split(',')
			      var filterVal=res["fields"].split(',')
            var filetitle=res["filetitle"]
            var jdata=res.records;
            if (jdata.length==0){
              this.$message("无数据，请先查询");
              this.downfileloading=false;
              return
            }
            if (this.activebt=='4' || this.activebt=='5' || this.activebt=='6'){
                jdata.map(item=>{
                  let images=item.images.split(',').filter(i=>i!='')
                  if (images.length>0){
                    item.images=images.map(it=>'http://image.yh59481717.com/'+it+'.jpg').join(';')
                  }
                  //item.cmemo=item.cmemo.split('#').join('<br/>')
                })
            }
            //console.log("拿到数据。。。。",jdata)
            //console.log(headers,filterVal,filetitle)
            this.$api.export2Excel(headers, filterVal, jdata, filetitle);
            this.downfileloading=false;
            return;
          }else{
            this.$message.error(res.message);
          }
          if (res["status"] == "-2") {
            this.$parent.$parent.$parent.swapToken();
          }
          this.downfileloading=false;
        },
        (failure) => {}
      );
    },
    handleCurrentChange(row){
    },
    nextPre(flag){
      let idx=this.QueryData3index;
      let i=-1;
      if (flag=="p"){
        for (i = idx-1; i >-1; i--){
          if (i==-1) break;
          if (this.QueryData3[i].imagelist){
            break;
          }
        }
        if(i==-1){
          this.$message.error("前面已经没有照片了");
          return
        }
      }
      if (flag=="n"){
        for (i = idx+1;i <this.QueryData3.length; i++){
          if (i==this.QueryData3.length) break;
          if (this.QueryData3[i].imagelist){
            break;
          }
        }
        if(i==(this.QueryData3.length)){
          this.$message.error("后面已经没有照片了");
          return
        }
      }
      this.QueryData3index=i;
      this.ajImageLists=this.QueryData3[i].imagelist
      this.$refs.multipleTable3.setCurrentRow(this.QueryData3[i]);
    },
    showimage(idx){
      this.QueryData3index=idx;
      this.ajImageLists=this.QueryData3[idx].imagelist
      this.showajImage=true;  
    },
    handleSizeChange(val) {
      this.pagesize=val
      let pagesizes=JSON.parse(localStorage.getItem("pagesizes") || "{}");
      pagesizes["security"]=val
      localStorage.setItem("pagesizes",JSON.stringify(pagesizes))
      if (this.totalcount==0) return
      this.getSaleList(0)
    },
    handlePageChange(val) {
      if (this.totalcount==0) return
      this.pageno=val
      this.getSaleList(0)
    },
    dateRange(e){
      console.log(e,this.valueDate)
    },
    getSaleList(bt){
      this.listLoading=true
      let para={};
      if (this.valueDate!=null){
        para.ksrq=this.valueDate[0]
        para.jsrq=this.valueDate[1]
      }
      para.usecode=this.usecode;
      para.station=this.station;
      if (bt>0)this.activebt=bt;
      let data = {
        lx: "getsecuritylist"+(this.activebt<3 ? '1' : (this.activebt==3 ? '2' : '3')),
        sqlone:(bt>0?bt:this.activebt),
        sqltwo:JSON.stringify(para),
        pageno:(bt>0?-1:this.pageno),
        pagesize:this.pagesize,
        procext:"report"
      };
      this.$api.post("",data,
        (res) => {
          console.log(res)
          if (res["status"] == "1") {
            this.totalcount=parseInt(res.totalcount)
            this.pageno=parseInt(res.pageno)
            this.pagesize=parseInt(res.pagesize)
            //console.log(res.records)
            if (this.activebt<3)
              this.QueryData1=res.records
            if (this.activebt==3)
              this.QueryData2=res.records
            if (this.activebt>3){
              this.QueryData3=res.records
              this.QueryData3.map(item=>{
                let images=item.images.split(',').filter(i=>i!='')
                if (images.length>0){
                  item.imagelist=images.map(it=>'http://image.yh59481717.com/'+it+'.jpg')
                }
                item.cmemo=item.cmemo.split('#').join('<br/>')
              })
              
            }
            this.refrsh=!this.refrsh;
          }else
            this.$message.error(res["message"]);
          if (res["status"] == "-2") {
            this.$parent.$parent.$parent.swapToken();
          }
          this.listLoading=false
        },
        (failure) => {}
      );
    },
    EnterFun(e){

    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return 'background-color: #f0f0f0;'
        }
    },
    getStationList(){
      let data = {
        lx: "getstationlist",
        procext:"report"
      };
      this.$api.post("",data,
        (res) => {
          if (res["status"] == "1") {
            this.stationList=res.records
            return
          }else
            this.$message.error(res["message"]);
          if (res["status"] == "-2") {
            this.$parent.$parent.$parent.swapToken();
          }
        },
        (failure) => {}
      );
    },
    initDivSize() {
      this.isPc=this.$store.getters.GettabBarInfo("isPc");
      if (this.isPc){
        //this.pagelayout="total,sizes,prev,pager,next,jumper"
      }else{
        this.pagelayout="total,prev,pager,next"
        this.btCaption=['站点','报表','计费','合格','一般','严重']
      }
      
    },
  },
  created() {
   let pagesizes=JSON.parse(localStorage.getItem("pagesizes") || "{}");
    if (pagesizes && pagesizes["security"])
      this.pagesize=parseInt(pagesizes["security"])
    //this.getSaleList(2)
  },
};
</script>
<!--公共样式(或者是width: 0;)-->

<style src = "../../Styles/cover.css"></style>
<style src = "../../Styles/common.css"></style>
<style src = "../../Styles/iconfont/iconfont.css"></style>

<style>
.backBoxbb .el-main {
  padding: 10px 20px 5px 10px !important;
}
.backBoxbb .flex_row .el-input-group__prepend{
  padding: 0 10px !important;
}
.backBoxbc .el-drawer {
 background-color:rgba(0,0,0,0.1); 
}
</style>

<style scoped>
.myscrollbar::-webkit-scrollbar{
display:none;
}
.container {
  width: 100%;
  height: 100%;

}
.oneLine {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.flex_row{
    display: flex;             /*设置为flex布局*/
    justify-content: flex-start;   /*水平居中*/
    align-items: center;       /*垂直居中*/
    flex-direction: row;
}
.flex_col{
    display: flex;             /*设置为flex布局*/
    justify-content: flex-start;   /*水平居中*/
    align-items: flex-start;       /*垂直居中*/
    flex-direction: column;
}


</style>